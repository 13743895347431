<template>
  <div id="app">
    <!-- <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay> -->

    <!-- loading skeleton -->
    <!-- <v-skeleton-loader
    class="mx-2"
      max-width="5000"
      height="80"
      type="actions"
      v-show="loading == true"
    ></v-skeleton-loader>
    <v-skeleton-loader
      max-width="200"
      class="mt-n15"
      type="card-heading"
      v-show="loading == true"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-2 my-n2"
      max-width="5000"
      height="80"
      type="image"
      v-show="loading == true"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="my-n5"
      max-width="5000"
      height="80"
      type="table-heading"
      v-show="loading == true"
    ></v-skeleton-loader>
    <v-skeleton-loader
      class="mx-2"
      max-width="5000"
      type="image,actions"
      v-show="loading == true"
    ></v-skeleton-loader> -->
    <!-- loading skeleton -->

    <v-col v-show="this.valid == false">
      <v-alert text type="error">
        Kamu tidak punya akses Ke halaman "Absen"
      </v-alert>
    </v-col>

    <v-col v-show="this.valid == true">
      <!-- Alert Data Tersimpan -->
      <v-alert style="position:fixed;z-index:2;right:20px;"
        dismissible
        color="success"
        border="left"
        elevation="4"
        colored-border
        v-model="Alert"
        transition="slide-x-transition"
      >
        <v-icon class="mr-1">check-circle</v-icon>
        Data Berhasil Disimpan
      </v-alert>

      <!-- Alert Data Sudah Terhapus -->
      <v-alert style="position:fixed;z-index:2;right:20px;"
        dismissible
        color="error"
        border="left"
        elevation="4"
        colored-border
        v-model="AlertDataTerhapus"
        transition="slide-x-transition"
      >
        Data Berhasil Dihapus
      </v-alert>

      <!-- Alert Data Berhasil Terubah -->
      <v-alert style="position:fixed;z-index:2;right:20px;"
        dismissible
        color="info"
        border="left"
        elevation="4"
        colored-border
        v-model="AlertBerhasilTerubah"
        transition="slide-x-transition"
      >
        Data Berhasil Diubah
      </v-alert>

      <v-card tile outlined class="elevation-3 pa-2">
        <v-toolbar
          flat
          outlined
          height="55"
          color="dark"
          v-resize="onResize"
        >
        <v-toolbar-title>
            Data Absen
          </v-toolbar-title>
          <v-divider inset vertical class="ml-2"></v-divider>
          <v-spacer></v-spacer>

        <div>
            <v-select
            v-show="Seleksi == true"
            class="text-capitalize mt-5"
            color="dark"
            v-model="SeleksiFilter"
            style="padding-right: 4px"
            append-icon=""
          :items="['Hadir','Terlambat','Tidak Masuk','Sakit','Izin','Izin Dokter']"
          label="Filter"
          :disabled="loading == true"
        >
        </v-select>
          </div>

          <!-- Btn Tanggal Filter Desktop -->
          <div>
            <v-btn
              v-show="mobile == false"
              class="text-capitalize"
              @click="filterTgl"
              color="dark"
              outlined
              text
              :disabled="loading == true"
            >
              <v-icon class="ml-n2 mr-1">mdi-calendar</v-icon>
              <span class="subtitle-1">Filter Tanggal</span>
            </v-btn>
          </div>

          <!-- Btn Tanggal Filter Mobile -->
          <div>
            <v-btn
              v-show="mobile == true"
              class="text-capitalize"
              @click="filterTgl"
              color="dark"
              outlined
              small
              text
              fab
              :disabled="loading == true"
            >
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </div>

          <!-- Tools  -->
          <div class="text-center ml-2 mr-n2">
            <v-menu
              v-model="MenuExport"
              :close-on-content-click="false"
              :nudge-width="1"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- Btn Export Mode Biasa -->
                <v-btn
                  @click="MenuExport = !MenuExport"
                  class="text-capitalize"
                  v-show="mobile == false"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  text
                  :disabled="loading == true"
                >
                  <v-icon class="ml-n2 mr-1">mdi-tools</v-icon>
                  <span class="subtitle-1">Tools</span>
                  <v-icon class="ml-1 mr-n3">{{ MenuExport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </template>

              <v-card outlined height="190" class="pa-2" v-show="MenuExport">
                <!-- TAMBAH DATA -->
                <div class="mb-2">
                  <v-btn
                    text
                    block
                    v-show="ValidCreate == true"
                    outlined                       
                    @click.native="DialogTambahAbsen = true"
                    class="text-capitalize subtitle-1"
                  >
                    Tambah Data
                    <v-icon right>mdi-plus</v-icon>
                  </v-btn>
                </div>

                <!-- IMPORT EXCEL -->
                <div class="mb-2">
                  <v-btn
                    text
                    block
                    outlined                       
                    @click.native="importExcelOpen"
                    class="text-capitalize subtitle-1"
                  >
                    Import Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </div>

                <!-- EXPORT PDF -->
                <div class="mb-2">
                  <v-btn
                    text
                    block
                    outlined
                    @click.native="PdfExport"
                    class="text-capitalize subtitle-1"
                  >
                    Export Pdf
                    <v-icon right>mdi-adobe-acrobat</v-icon>
                  </v-btn>
                </div>

                <!-- EXPORT EXCEL -->
                <div>
                  <v-btn 
                    text
                    block
                    outlined                       
                    @click.native="excelExport"
                    class="text-capitalize subtitle-1"
                  >
                    Export Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </div>

              </v-card>
            </v-menu>

            <!-- Btn Export Dialog Mode Mobile -->
            <v-dialog
              v-model="DialogPilihExport"
              max-width="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="mobile == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  small
                  text
                  fab
                  :disabled="loading == true"
                >
                  <v-icon>mdi-table-arrow-right</v-icon>
                </v-btn>
              </template>
              <v-card class="rounded-lg">
                <v-toolbar dense>
                  <v-layout justify-center>
                    <v-toolbar-title>
                      Tools
                    </v-toolbar-title>
                  </v-layout>
                </v-toolbar>
                <v-card class="pa-2">
                  <v-btn v-show="ValidCreate == true" text block outlined class="text-capitalize subtitle-1 mb-2" @click.native="DialogTambahAbsen = true">
                    Tambah Data
                    <v-icon right>mdi-plus</v-icon>
                  </v-btn>
                  
                  <v-btn text block outlined class="text-capitalize subtitle-1 mb-2" @click.native="PdfExport">
                    Export Pdf
                    <v-icon right>mdi-adobe-acrobat</v-icon>
                  </v-btn>

                  <v-btn text block outlined class="text-capitalize subtitle-1 mb-2" @click.native="excelExport">
                    Export Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>

                  <v-btn text block outlined class="text-capitalize subtitle-1" @click.native="importExcelOpen">
                    Import Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </v-card>
              </v-card>
            </v-dialog>
          </div>

          <!-- Dialog Tambah Cabang -->
          <v-dialog
            v-model="DialogTambahAbsen"
            max-width="600px"
            scrollable
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="mobile == false && false"
                class="text-capitalize mr-n2"
                color="dark"
                v-bind="attrs"
                v-on="on"
                text
              >
                <v-icon class="mr-1">mdi-plus-thick</v-icon>
                Tambah
              </v-btn>

              <v-btn 
                class="mx-n3"
                v-show="mobile == true && false"
                v-bind="attrs"
                v-on="on"
                small
                text
                fab
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </template>
            <v-card class="rounded-lg">
              <v-toolbar flat height="64">
                <v-toolbar-title class="title">
                  <span class="text-h5 mx-1">{{ formTitleAbsen }}</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  @click="KeluarDialogAbsen"
                >
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-divider></v-divider>
              
              <v-card-text>
                <div class="mr-n3 ml-n3 mb-n2 mt-3">
                  <v-sheet outlined class="rounded-lg pa-5">
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-dialog
                          v-model="TanggalAbsen"
                          :return-value.sync="date"
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.Tanggal"
                              label="Tanggal Absen"
                              prepend-icon="mdi-calendar"
                              color="dark"
                              readonly
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.Tanggal"
                            @input="TanggalAbsen = false"
                            year-icon="mdi-calendar-blank"
                            color="red darken-4"
                            class="rounded-lg"
                            locale="id"
                          ></v-date-picker>
                        </v-dialog>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          readonly
                          label="NRK"
                          color="dark"
                          v-model="editedItem.Nrk"
                        >
                          <template v-slot:append-outer>
                            <v-dialog
                              v-model="DialogNrk"
                              max-width="1000px"
                              scrollable
                              persistent
                            >
                              <template v-slot:activator="{on, attrs}">
                                <v-btn
                                  dark
                                  x-small
                                  height="25"
                                  v-bind="attrs"
                                  v-on="on"
                                  depressed
                                  color="red darken-4"
                                >
                                  <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                              </template>
                              <v-card class="rounded-lg">
                                <v-toolbar
                                  flat
                                >
                                  <v-toolbar-title>
                                    <span class="headline"><label>{{ formTitleNrk }}</label></span>
                                  </v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    fab
                                    text
                                    small
                                    @click="DialogNrk = false"
                                  >
                                    <v-icon>mdi-window-close</v-icon>
                                  </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text>
                                  <div class="mb-n2 mr-n3 ml-n3">                                           
                                    <v-card outlined class="rounded-lg mt-3 elevation-6">
                                      <div class="pa-2">
                                        <ejs-grid
                                          ref='gridkaryawanview'
                                          height="208"
                                          gridLines='Both'
                                          id="kodekaryawanview"
                                          :dataSource="karyawan"
                                          :allowPaging="true"
                                          :allowSorting='true'
                                          :allowResizing='true'
                                          :toolbar='toolbarOptions'
                                          :recordDoubleClick="rowDblClickNrk"
                                          :pageSettings='pageSettings'
                                          :rowSelected="rowSelectedNrk" 
                                        >
                                          <e-columns>
                                            <e-column field='NRK' headerText='NRK' width=300></e-column>
                                            <e-column field='NAMA' headerText='Nama' width=150></e-column>
                                          </e-columns>
                                        </ejs-grid>
                                      </div>
                                    </v-card>                                      
                                  </div>     
                                </v-card-text>                                   
                                <v-divider></v-divider>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                  <v-btn
                                    dark
                                    color="red darken-4 mx-n2"
                                    class="text-capitalize"
                                    @click="SimpanNrk"
                                  >
                                    <v-icon class="mr-1">mdi-check-circle</v-icon>
                                    Simpan
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          clearable
                          label="Nama"
                          color="dark"
                          v-model="editedItem.Nama"                           
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          readonly
                          label="Kategori Absen"
                          color="dark"
                          v-model="editedItem.KategoriAbsen"
                        >
                          <template v-slot:append-outer>
                            <v-dialog
                              v-model="DialogKategoriAbsen"
                              max-width="1000px"
                              scrollable
                              persistent
                            >
                              <template v-slot:activator="{on, attrs}">
                                <v-btn
                                  dark
                                  x-small
                                  height="25"
                                  v-bind="attrs"
                                  v-on="on"
                                  depressed
                                  color="red darken-4"
                                >
                                  <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                              </template>
                              <v-card class="rounded-lg">
                                <v-toolbar
                                  flat
                                >
                                  <v-toolbar-title>
                                    <span class="headline"><label>{{ formTitleKategoriAbsen }}</label></span>
                                  </v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    fab
                                    text
                                    small
                                    @click="DialogKategoriAbsen = false"
                                  >
                                    <v-icon>mdi-window-close</v-icon>
                                  </v-btn>
                                </v-toolbar>
                                <v-divider></v-divider>
                                <v-card-text>
                                  <div class="mb-n2 mr-n3 ml-n3">                                           
                                    <v-card outlined class="rounded-lg mt-3 elevation-6">
                                      <div class="pa-2">
                                        <ejs-grid
                                          ref='gridkaryawanview'
                                          height="208"
                                          gridLines='Both'
                                          id="kodekaryawanview"
                                          :dataSource="kategoriabsen"
                                          :allowPaging="true"
                                          :allowSorting='true'
                                          :allowResizing='true'
                                          :toolbar='toolbarOptions'
                                          :recordDoubleClick="rowDblClickKategoriAbsen"
                                          :pageSettings='pageSettings'
                                          :rowSelected="rowSelectedKategoriAbsen" 
                                        >
                                          <e-columns>
                                            <e-column field='Kategori' headerText='Kategori' width=150></e-column>
                                            <e-column field='JamMasuk' headerText='Jam Masuk' width=150></e-column>
                                            <e-column field='JamPulang' headerText='Jam Pulang' width=150></e-column>
                                          </e-columns>
                                        </ejs-grid>
                                      </div>
                                    </v-card>                                      
                                  </div>     
                                </v-card-text>                                   
                                <v-divider></v-divider>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                  <v-btn
                                    dark
                                    color="red darken-4 mx-n2"
                                    class="text-capitalize"
                                    @click="SimpanKategoriAbsen"
                                  >
                                    <v-icon class="mr-1">mdi-check-circle</v-icon>
                                    Simpan
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="6" lg="6">
                        <v-text-field
                          dense
                          label="Scan Masuk"
                          color="dark"
                          v-model="editedItem.ScanMasuk"
                          type="time"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          label="Scan Pulang"
                          color="dark"
                          v-model="editedItem.ScanPulang"  
                          type="time"                         
                        ></v-text-field>
                      </v-col>
                      
                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          type="time"
                          label="Terlambat"
                          color="dark"
                          v-model="editedItem.Terlambat"                           
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          type="time"
                          label="Pulang Cepat"
                          color="dark"
                          v-model="editedItem.PulangCepat"                           
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          type="time"
                          label="Lembur"
                          color="dark"
                          v-model="editedItem.Lembur"                           
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          dense
                          clearable
                          label="Keterangan"
                          color="dark"
                          v-model="editedItem.Keterangan"                           
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </div>              
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <span class="d-inline-block text-truncate">
                  Status : <span>{{Status}}</span>
                </span>
                <v-spacer></v-spacer>

                <v-btn
                  dark
                  depressed
                  color="red darken-4 mx-n2"
                  class="text-capitalize"
                  @click="SimpanAbsen"
                >
                  <v-icon left>mdi-content-save</v-icon>
                  <span class="mr-1">Simpan</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <div v-show="loading == true">
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              height="6"
              color="red darken-4"
            ></v-progress-linear>
          </div>
        </v-toolbar>

        <div>
          <ejs-grid
            ref='gridabsen'
            style="height: 100%;"
            height="43vh"
            gridLines='Both'
            id="absenview"
            :filterSettings='FilterOptions'
            :allowFiltering='true'
            :dataSource="DataAbsen"
            :allowPaging="true"
            :allowSorting='true'
            :allowResizing='true'
            :allowGrouping='true'
            :toolbar='toolbarOptions'
            :commandClick="commandClick"
            :pageSettings='pageSettings'
            :allowExcelExport='true'
            :queryCellInfo='customiseCell'
            :allowPdfExport='true'
          >
            <e-columns>
              <e-column headerText="Aksi" textAlign='Center' width="120" :commands="commands" :lockColumn='true' :customAttributes="customAttributes">
                <div class="btn-group">
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowEdit($event)">
                    <i class="fa fa-pencil"></i>
                  </v-btn>
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowDelete($event)">
                    <i class="fa fa-trash"></i>
                  </v-btn>
                </div>
              </e-column>
              <e-column field='NRK' :filter="filter" headerText='NRK' textAlign='Left' width=150></e-column>
              <e-column field='Tanggal' :filter="filter" headerText='Tanggal' width=160></e-column>
              <e-column field='karyawan.Nama' :filter="filter" headerText='Nama' width=160></e-column>
              <e-column field='KategoriAbsen' :filter="filter" headerText='Kategori Absen' width=160></e-column>
              <e-column field='ScanMasuk' :filter="filter" headerText='Scan Masuk' width=160></e-column>
              <e-column field='ScanPulang' :filter="filter" headerText='Scan Pulang' width=160></e-column>
              <e-column field='Terlambat' :filter="filter" headerText='Terlambat' width=160></e-column>
              <e-column field='PulangCepat' :filter="filter" headerText='Pulang Cepat' width=160></e-column>
              <e-column field='Lembur' :filter="filter" headerText='Lembur' width=160></e-column>
              <e-column field='Keterangan' :filter="filter" headerText='Keterangan' width=160></e-column>
              <e-column field='Lokasi' :filter="filter" headerText='Lokasi' width=160></e-column>
              <e-column field='DiBuatOleh' headerText='DibuatOleh' textAlign='Left' width=150></e-column>
              <e-column field='DiBuatTgl' headerText='DibuatTgl' textAlign='Left' width=150></e-column>
              <e-column field='DiUbahOleh' headerText='DiubahOleh' textAlign='Left' width=150></e-column>
              <e-column field='DiUbahTgl' headerText='DiubahTgl' textAlign='Left' width=150></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </v-card>
    </v-col>
    <ExcelAbsen ref='Excel'></ExcelAbsen>

    <v-dialog
      v-model="dialogFilterTgl"
      max-width="350px"
      scrollable
    >
      <v-card class="rounded-lg">
        <v-toolbar flat height="65">
          <v-toolbar-title class="title">
            <span class="text-h5 mx-1">Filter Tanggal</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="loading == true"
            @click="dialogFilterTgl = false"
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        
        <v-card-text>
          <div class="mr-n3 ml-n3 mb-n2 mt-3">
            <v-sheet outlined class="rounded-lg pa-5">     
              <v-row>
                <!-- Dari Tanggal Mode Dialog-->
                <!-- <v-col cols="12" sm="12" md="12">
                  <v-dialog
                    ref="DialogDariTanggal"
                    v-model="DialogDariTanggal"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.DariTanggal"
                        label="Dari Taggal"
                        prepend-icon="mdi-calendar"
                        color="dark"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.DariTanggal"
                      class="rounded-lg"
                      year-icon="mdi-calendar-blank"
                      color="red darken-4"
                      locale="id"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        class="text-capitalize"
                        color="red darken-4"
                        @click="modal = false"
                      >
                        Batal
                      </v-btn>
                      <v-btn
                        text
                        class="text-capitalize"
                        color="red darken-4"
                        @click="$refs.DialogDariTanggal.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col> -->
                
                <!-- Sampai Tanggal Mode Dialog-->
                <!-- <v-col cols="12" sm="12" md="12">
                  <v-dialog
                    ref="DialogSampaiTanggal"
                    v-model="DialogSampaiTanggal"
                    :return-value.sync="date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.SampaiTanggal"
                        label="Sampai Taggal"
                        prepend-icon="mdi-calendar"
                        color="dark"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedItem.SampaiTanggal"
                      class="rounded-lg"
                      year-icon="mdi-calendar-blank"
                      color="red darken-4"
                      locale="id"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        class="text-capitalize"
                        color="red darken-4"
                        @click="modal = false"
                      >
                        Batal
                      </v-btn>
                      <v-btn
                        text
                        class="text-capitalize"
                        color="red darken-4"
                        @click="$refs.DialogSampaiTanggal.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col> -->

                <!-- Dari Tanggal Mode Biasa -->
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-text-field
                    label="Dari Tanggal"
                    color="dark"
                    type="date"
                    dense
                    v-model="DariTanggal"
                  >
                  </v-text-field>
                </v-col>

                <!-- Sampai Tanggal Mode Biasa -->
                <v-col cols="12" sm="12"  md="12" lg="12">
                  <v-text-field
                    v-show="PilihanFilter != 'Tidak Absen'"
                    label="Sampai Tanggal"
                    color="dark"  
                    type="date"
                    dense
                    v-model="SampaiTanggal"
                  >
                  </v-text-field>
                </v-col>

                <!-- seleksi di filter tgl -->
                <!-- <v-col v-for="pilihan in Select" :key="pilihan" cols="12" sm="6"  md="6" lg="6" class="mt-n6">
                  <v-checkbox color="red darken-4" :value="pilihan" :key="pilihan" :label="pilihan" v-model="PilihanFilter" hide-details>
                  </v-checkbox>
                </v-col> -->

                <!-- <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    
                    label="Tampilkan Semua"
                    color="red darken-4"
                    class="mb-n8"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-checkbox
                    
                    color="red darken-4"
                    label="Terlambat"
                  ></v-checkbox>
                </v-col> -->
              </v-row>
            </v-sheet>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            dark
            depressed
            color="red darken-4 mx-n2"
            class="text-capitalize"
            @click="FilterTgl"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Page, Sort, Group, Resize, Toolbar, Search, CommandColumn, ExcelExport, PdfExport, Filter } from "@syncfusion/ej2-vue-grids";
import api from "@/services/http";
import ExcelAbsen from '@/components/ExcelAbsen'
Vue.use(GridPlugin);

export default {
    components: {
      ExcelAbsen,
    },
  data() {
    return {
      FilterTanggal: {
        DariTanggal: "",
        SampaiTanggal: "",
      },
      FilterTanggalDefault: {
        DariTanggal: "",
        SampaiTanggal: "",
      },

      date: new Date().toISOString().substr(0, 10),
      DialogSampaiTanggal: false,
      DialogDariTanggal: false,
      mobile:null,
      Seleksi:false,
      valid:null,
      SeleksiFilter:null,
      Permission:[],
      ValidCreate:false,
      DialogNrk:false,
      TanggalAbsen:false,
      passbaru:null,
      KodeCabang:null,
      DariTanggal:null,
      SampaiTanggal:null,
      textAktif:true,
      Select:['Tampilkan Semua','Terlambat','Tidak Absen'],
      umenu:[],
      karyawan:[],
      Storage:[],
      DialogKategoriAbsen:false,
      kategoriabsen:[],
      verifpassbaru:null,
      loading:true,
      dialogFilterTgl:false,
      Kode:null,
      DialogPilihExport:false,
      DialogPilihImport:false,
      windowSize: {x: 0, y: 0},
      MenuExport: false,
      MenuImport: false,
      commands: [],
      customAttributes : {class: 'customcss'},
      Alert:false,
      akun:[],
      usermenu:null,
      PilihanFilter:null,
      AlertDataTerhapus:false,
      AlertBerhasilTerubah:false,
      user:[],
      token:null,
      DialogTambahAbsen: false,
      form: new FormData,
      editedIndex: -1,
      FilterOptions: {
        type: 'Menu'
      },
      filter: {
        type : 'CheckBox'
      },
      defaultItem: {
        Tanggal: "",
        Nrk: "",
        Nama: "",
        KategoriAbsen:"",
      },
      editedItem: {
        Tanggal: "",
        Nrk: "",
        Nama: "",
        KategoriAbsen:"",
      },
      toolbarOptions: ['Search'],
      DataAbsen: [],
      DataAbsenReal: [],
      pageSettings: {pageSize: 10, pageSizes :['5','10','15','20','50','All']},
    };
  },
  

  provide: {
    grid: [Page, Sort, Group, Resize, Toolbar, CommandColumn, Search,ExcelExport,PdfExport,Filter ]
  },

 async mounted(){
    this.token = localStorage.getItem('token')
    this.user = JSON.parse(localStorage.getItem('user'))
    if (this.Permission.some(a => a == 'R')) {
      // this.getdata()
      this.valid = true
      this.getkaryawan()
      this.getkategoriabsen()
    }else{
      this.loading = false
      this.valid = false
    }
    if (this.Permission.some(a => a == 'C')) {
     this.ValidCreate = true
    }
    if (this.Permission.some(a => a == 'U')) {
     this.commands.push({buttonOption: { cssClass: "e-flat Edit", iconCss: "e-edit e-icons", content:' '} })
    }
    if (this.Permission.some(a => a == 'D')) {
     this.commands.push({ buttonOption: { cssClass:"e-flat Delete", iconCss: "e-delete e-icons", content:' '} })
    }
    // this.$on('refresh', () => {
    //   this.valid = true
    //   this.getdata()
    // }),
    // await Promise.all([this.saya()])
  },

  created(){
    //get action for this page
    console.log('param',this.$route.params.Permission)
    let permisi = this.$route.params.Permission
    this.Permission = permisi == null ? [] : permisi.split(',')
    },

  computed: {
    formTitleAbsen() {
      return this.editedIndex === -1 ? 'Tambah Absen Baru' : 'Ubah Data Absen'
    },
    formTitleNrk(){
      return this.editedIndex === -1 ? 'Pilih Nrk Karyawan' : 'Ubah Nrk Karyawan'
    },
    formTitleKategoriAbsen(){
      return this.editedIndex === -1 ? 'Pilih Kategori Absen' : 'Ubah Kategori Absen'
    },
    Status () {
      return this.editedIndex === -1 ? 'Baru' : 'Ubah'
    },
  },
  
  watch: {
    SeleksiFilter(v){
      this.FungsiSeleksi(v)
    },
      // let a = this.DataCabang.filter( function(item){return (item.UserMenu == newval.toUpperCase());} )
      // this.umenu = a
    windowSize(){
      if (this.windowSize.x < 450) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },

    DialogTambahCabang (val) {
      val || this.KeluarDialogAbsen()
    },

    Alert(){
      this.AlertItem()
    },
    AlertDataTerhapus(){
      this.AlertItem()
    },
    AlertBerhasilTerubah(){
      this.AlertItem()
    },
  },

  methods: {
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    getDateAll(DariTanggal, SampaiTanggal) {
      const DariTanggalAwal = new Date(DariTanggal.getTime());
      DariTanggalAwal.setDate(DariTanggalAwal.getDate() + 1);
      const dates = [];
      while (DariTanggalAwal < SampaiTanggal) {
        dates.push(new Date(DariTanggalAwal).toISOString().substring(0, 10));
        DariTanggalAwal.setDate(DariTanggalAwal.getDate() + 1);
      }
      return dates;
    },
    FungsiSeleksi(v){
      if(v == 'Terlambat'){
        this.DataAbsen = this.DataAbsenReal
        this.DataAbsen = [...this.DataAbsen]
        this.DataAbsen = this.DataAbsen.filter( function(x){return (x.ScanMasuk.substring(0,2) > x.kategori_absen.JamMasuk.substring(0,2))} )
        console.log(this.DataAbsen)
      }else if(v == 'Hadir'){
        this.DataAbsen = this.DataAbsenReal
      }else if(v == 'Tidak Masuk'){
        this.DataAbsen = this.DataAbsenReal
        let newKaryawan = [];
        let allkaryawan = this.karyawan
        if(this.DariTanggal != null && this.SampaiTanggal != null){
          let tanggal = this.getDateAll(new Date(this.DariTanggal),new Date(this.SampaiTanggal))
           for(const tgl of tanggal) {
            for (let ss = 0; ss < allkaryawan.length; ss++) {
              const obj = {
                Tanggal : tgl,
                NRK : allkaryawan[ss].NRK,
                Keterangan:"A",
                Lembur:null,
                karyawan:allkaryawan[ss].karyawan,
                kategori_absen:allkaryawan[ss].kategori_absen,
                Lokasi:null,
                PulangCepat:null,
                ScanMasuk:null,
                ScanPulang:null,
                Terlambat:null,
                KategoriAbsen:allkaryawan[ss].KategoriAbsen,
                DiBuatOleh:"System",
                DiUbahOleh:"System",
                DiUbahTgl:tgl,
                DiBuatTgl:tgl
              }
              newKaryawan.push(obj)
            }
          }
        }else if(this.DariTanggal != null && this.SampaiTanggal == null){
          allkaryawan.forEach(s => {
          s.Tanggal = this.DariTanggal
          s.DiBuatOleh = "System"
          s.DiUbahOleh = "System"
          s.DiBuatTgl = this.DariTanggal
          s.DiUbahTgl = this.DariTanggal
        });
        }
        if(newKaryawan.length == 0){
          newKaryawan = allkaryawan
          newKaryawan.forEach(a => {
            a.Tanggal = this.DariTanggal
            a.DiBuatOleh = "System"
            a.DiUbahOleh = "System"
            a.DiBuatTgl = this.DariTanggal
            a.DiUbahTgl = this.DariTanggal
          });
        }
        let listData = []
          for (let a = 0; a < newKaryawan.length; a++) {
            for (let b = 0; b < this.DataAbsen.length; b++) {
                if (newKaryawan[a].NRK == this.DataAbsen[b].NRK && newKaryawan[a].Tanggal == this.DataAbsen[b].Tanggal) {
                  listData.push(newKaryawan[a])
              }
            }
          }
        // console.log('ssas',newKaryawan)
        this.DataAbsen= newKaryawan.filter(x => !listData.includes(x));
         this.DataAbsen = [...this.DataAbsen]
        //  console.log(this.DataAbsen)
        // }else{
        //   this.$swal.fire({
        //   toast: true,
        //   icon: 'error',
        //   title: 'Hanya Bisa Perhari',
        //   animation: false,
        //   position: 'top-right',
        //   showConfirmButton: false,
        //   timer: 3000,
        //   timerProgressBar: true})
        // }

      }else if(v == 'Izin'){
        this.DataAbsen = this.DataAbsenReal
        this.DataAbsen = this.DataAbsen.filter( function(x){return (x.Keterangan == "I")} )
      }else if(v == 'Izin Dokter'){
        this.DataAbsen = this.DataAbsenReal
        this.DataAbsen = this.DataAbsen.filter( function(x){return (x.Keterangan == "ID")} )
      }
    },
    cariKode(Kode){
      if (Kode != null) {
          this.textAktif = false
        }else{
          this.textAktif = true
        }
      if (Kode == null || Kode == '' || Kode.length == 0) {
        this.editedIndex = -1
          this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }else{
      this.Kode = Kode.toUpperCase()
      let a = this.DataCabang.filter( function(item){return (item.Kode == Kode.toUpperCase());} );
      if (a.length > 0) {
          this.editedIndex = 1;
          this.textAktif = false
          this.editedItem.Nama = a[0].Nama
          // this.editedItem.UserMenu = a[0].UserMenu
        }else{
          //do nothing
        }
      }
    },
  async saya(){
       if (this.$menu == undefined) {
         await api.get('/menu?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
        res=>{
          if (res) {
            this.akun = res.data
            let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
            //do noting
          }
        }).catch(err=>{
          // alert(err)
          if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
            this.logout()
          }
        })
       }else{
        this.akun = this.$menu
        let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
       }     
    },
      // cekmenu(){
        
      // },


    FilterTgl(){
      if (this.PilihanFilter == null) {
            if (this.DariTanggal != null && this.SampaiTanggal == null) {
              this.dialogFilterTgl = false
              this.loading = true
              api.get('/absentanggal/'+this.DariTanggal+'/?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
              res => {
                // console.log(res);
                // alert('ok')
                this.DataAbsenReal = res.data
                this.DataAbsen = res.data
                this.loading = false
                this.Seleksi = true
                this.SeleksiFilter = 'Hadir'
                // for (let index = 0; index < this.DataAbsen.length; index++) {
                //       if(!this.DataAbsen[index].ScanMasuk){
                //         //do nothing
                //       }else{
                //         this.DataAbsen[index].JamMasuk = this.DataAbsen[index].JamMasuk.substring(0,2)
                //         this.DataAbsen[index].ScanJamMasuk = this.DataAbsen[index].ScanMasuk.substring(0,2)
                //     }
                //   }
                // this.dialogFilterTgl = false
              },
              err => {
                console.log(err);
              })
            }else if (this.DariTanggal !=null && this.SampaiTanggal != null) {
              this.dialogFilterTgl = false
              this.loading = true
              api.get('/absentanggal/'+this.DariTanggal+'/'+this.SampaiTanggal+'/?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
              res => {
                // console.log(res);
                this.DataAbsenReal = res.data
                this.DataAbsen = res.data
                this.loading = false
                this.Seleksi = true
                // for (let index = 0; index < this.DataAbsen.length; index++) {
                //       if(!this.DataAbsen[index].ScanMasuk){
                //         //do nothing
                //       }else{
                //         this.DataAbsen[index].JamMasuk = this.DataAbsen[index].JamMasuk.substring(0,2)
                //         this.DataAbsen[index].ScanJamMasuk = this.DataAbsen[index].ScanMasuk.substring(0,2)
                //     }
                //   }
                // this.dialogFilterTgl = false
              },
              err => {
                console.log(err);
              })
          }       
      }else if (this.PilihanFilter == "Terlambat") {
            if (this.DariTanggal != null && this.SampaiTanggal == null) {
              this.dialogFilterTgl = false
              this.loading = true
              api.get('/absenterlambat/'+this.DariTanggal+'/?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
              res => {
                // console.log(res);
                this.DataAbsen = res.data
                this.loading = false
                // for (let index = 0; index < this.DataAbsen.length; index++) {
                //       if(!this.DataAbsen[index].ScanMasuk){
                //         //do nothing
                //       }else{
                //         this.DataAbsen[index].JamMasuk = this.DataAbsen[index].JamMasuk.substring(0,2)
                //         this.DataAbsen[index].ScanJamMasuk = this.DataAbsen[index].ScanMasuk.substring(0,2)
                //     }
                //   }
                // this.dialogFilterTgl = false
              },
              err => {
                console.log(err);
              })
            }else if (this.DariTanggal !=null && this.SampaiTanggal != null) {
              this.dialogFilterTgl = false
              this.loading = true
              api.get('/absenterlambat/'+this.DariTanggal+'/'+this.SampaiTanggal+'/?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
              res => {
                // console.log(res);
                this.DataAbsen = res.data
                this.loading = false
                // for (let index = 0; index < this.DataAbsen.length; index++) {
                //       if(!this.DataAbsen[index].ScanMasuk){
                //         //do nothing
                //       }else{
                //         this.DataAbsen[index].JamMasuk = this.DataAbsen[index].JamMasuk.substring(0,2)
                //         this.DataAbsen[index].ScanJamMasuk = this.DataAbsen[index].ScanMasuk.substring(0,2)
                //     }
                //   }
                // this.dialogFilterTgl = false
              },
              err => {
                console.log(err);
              })
          }
      }else if (this.PilihanFilter == "Tidak Absen") {
            if (this.DariTanggal != null && this.SampaiTanggal == null) {
              this.dialogFilterTgl = false
              this.loading = true
              api.get('/tidakabsen/'+this.DariTanggal+'/?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
              res => {
                res.data.forEach(x => {
                  x.tanggal = this.DariTanggal
                  x.kategoriAbsen = "DayTime"
                });
                this.DataAbsen = res.data
                this.loading = false
                // for (let index = 0; index < this.DataAbsen.length; index++) {
                //       if(!this.DataAbsen[index].ScanMasuk){
                //         //do nothing
                //       }else{
                //         this.DataAbsen[index].JamMasuk = this.DataAbsen[index].JamMasuk.substring(0,2)
                //         this.DataAbsen[index].ScanJamMasuk = this.DataAbsen[index].ScanMasuk.substring(0,2)
                //     }
                //   }
                // this.dialogFilterTgl = false
              },
              err => {
                console.log(err);
              })
            }else if (this.DariTanggal !=null && this.SampaiTanggal != null) {
              this.dialogFilterTgl = false
              this.loading = true
              api.get('/tidakabsen/'+this.DariTanggal+'/?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
              res => {
                // console.log(res);
                res.data.forEach(x => {
                  x.tanggal = this.DariTanggal
                  x.kategoriAbsen = "DayTime"
                });
                this.DataAbsen = res.data
                this.loading = false
                // for (let index = 0; index < this.DataAbsen.length; index++) {
                //       if(!this.DataAbsen[index].ScanMasuk){
                //         //do nothing
                //       }else{
                //         this.DataAbsen[index].JamMasuk = this.DataAbsen[index].JamMasuk.substring(0,2)
                //         this.DataAbsen[index].ScanJamMasuk = this.DataAbsen[index].ScanMasuk.substring(0,2)
                //     }
                //   }
                // this.dialogFilterTgl = false
              },
              err => {
                console.log(err);
              })
          }
      }
      
    },

    commandClick: function(args) {
      if (args.target.classList.contains("custombutton")){
        // let data = JSON.stringify(args.rowData);
		
        // console.log(args.rowData);
      } else if (args.target.classList.contains("Delete")){
        var r = confirm("Yakin Hapus Data?");
        if (r == true) { 
          api
            .delete("/absen/"+args.rowData.id+'?token='+this.token)
            .then((res) =>{
              // console.log(res);
              if (res) {
                this.getdata()
              this.AlertDataTerhapus = true
              }
              
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (args.target.classList.contains("Edit")){
        // console.log(args.rowData)
        this.KodeCabang = args.rowData.KodeCabang
        // let data = args
            this.editedIndex = 1;
            // console.log(data)
            this.editedItem.KodeCabang = args.rowData.kodecabang
            this.editedItem.NamaCabang = args.rowData.namacabang
            this.editedItem.KodePerusahaan = args.rowData.kodeperusahaan
            this.DialogTambahAbsen = true

          // if (args.rowData.Aktif == 0) {
          //   this.dataTemp = args.rowData
          //   this.dialogAktifData = true
          //   }else{
          //       let data = args;
          //       this.editedIndex = 1;
          //       this.Kode = data.rowData.Kode
          //       this.editedItem.Nama = data.rowData.Nama
          //       this.editedItem.Password = data.rowData.Password
          //       this.editedItem.UserMenu = data.rowData.UserMenu
          //       // this.dialogWO = true;
          //   }
      }
    },
    importExcelOpen(){
        this.$refs.Excel.Open()
    },
    customiseCell: function(args) {
          if (args.column.field === 'NRK' ||
              args.column.field === 'karyawan.Nama' ||
              args.column.field === 'KategoriAbsen' ||
              args.column.field === 'ScanMasuk' ||
              args.column.field === 'ScanPulang' ||
              args.column.field === 'Tanggal' ||
              args.column.field === 'Terlambat' ||
              args.column.field === 'PulangCepat' ||
              args.column.field === 'Lembur' ||
              args.column.field === 'Keterangan'
              ) {
                if (parseInt(args.data['ScanMasuk']) == 8 || parseInt(args.data['ScanMasuk']) > parseInt(args.data['kategori_absen']['JamMasuk'])) {
                args.cell.classList.add('TelatAbsen');
            }
                if (parseInt(args.data['Terlambat']) == "01.00") {
                    args.cell.classList.add('Terlambat');
                }
          }
        },

    PdfExport(){
      let data = document.getElementById('absenview').ej2_instances[0];
      // console.log(data)
      data.pdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName:"ReportAbsen.pdf"
      }
      data.columns[1].visible = false
      data.columns[4].visible = false
      data.columns[3].visible = false
      // let pdfExportProperties = {
       
      // };
      // this.$refs.gridcabang.columns[0].visible = false;
      // this.$refs.gridcabang.columns[1].visible = true;
      this.$refs.gridabsen.pdfExport(data.pdfExportProperties);
    },
    excelExport(){
      this.$refs.gridabsen.excelExport();
      // console.log(this.$refs.gridabsen)
      // let data = document.getElementById('cabangview').ej2_instances[0];
      // console.log(data)
      // data.excelExport();
    },


    KeluarDialogAbsen () {
      this.DialogTambahAbsen = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.Kode = null
        this.textAktif = true
      })
    },

    // beforeExport: function(args) {
      
    // },
    getkaryawan(){
      if (this.valid == true) {
        api.get('/karyawankerja?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
				res => {
					// console.log(res);
          let data = res.data
          let datafinal = []
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            element.data = new Object
            element.data.NRK = element.NRK
            element.data.DiBuatOleh = null
            element.data.DiUbahOleh = null
            element.data.DiBuatTgl = null
            element.data.DiUbahTgl = null
            element.data.Terlambat = null
            element.data.Lokasi = null
            element.data.PulangCepat = null
            element.data.ScanMasuk = null
            element.data.ScanPulang = null
            element.data.Keterangan = 'A'
            element.data.KategoriAbsen = element.KategoriAbsen
            element.data.Lembur = null
            element.data.Tanggal = null
            element.data.karyawan = new Object
            element.data.karyawan.Nama = element.Nama
            element.data.karyawan.NRK = element.NRK
            element.data.karyawan.KategoriAbsen = element.KategoriAbsen
            element.data.karyawan.Cabang = element.Cabang
            element.data.kategori_absen = new Object
            element.data.kategori_absen.id = null
            element.data.kategori_absen.StatusData = "1"
            element.data.kategori_absen.KodeAbsen = "0101/000001"
            element.data.kategori_absen.Kategori = "Daytime"
            element.data.kategori_absen.JamPulang = "17.00"
            element.data.kategori_absen.JamMasuk = "08.00"
            element.data.kategori_absen.DiUbahTgl = null
            element.data.kategori_absen.DiBuatTgl = null
            element.data.kategori_absen.DiUbahOleh = null
            element.data.kategori_absen.DiBuatOleh = null
            datafinal.push(element.data)
          }
          this.karyawan = datafinal
          this.dialogFilterTgl = true
          this.loading = false
				},
				err => {
					console.log(err);
				}
			)
      }
    },

    getkategoriabsen(){
      if (this.valid == true) {
        api.get('/kategoriabsen?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
				res => {
					// console.log(res);
          this.kategoriabsen = res.data
				},
				err => {
					console.log(err);
				}
			)
      }
    },

    getdata(){
      let date = new Date().toISOString().slice(0,10)
      if (this.valid == true) {
         api.get('/absentanggal/'+date+'/'+date+'/?token='+this.token,{ headers: {"Authorization" : `Bearer ${this.token}`} }).then(
              res => {
                // console.log(res);
                this.DataAbsen = res.data
                this.loading = false
                for (let index = 0; index < this.DataAbsen.length; index++) {
                      if(!this.DataAbsen[index].kategori_absen.ScanMasuk){
                        //do nothing
                      }else{
                        this.DataAbsen[index].kategori_absen.JamMasuk = this.DataAbsen[index].kategori_absen.JamMasuk.substring(0,2)
                        this.DataAbsen[index].kategori_absen.ScanJamMasuk = this.DataAbsen[index].kategori_absen.ScanMasuk.substring(0,2)
                    }
                  }
                this.dialogFilterTgl = false
              },
              err => {
                console.log(err);
              })
      }
      
    },
    SimpanAbsen(){
      if(this.formTitleAbsen === "Tambah Absen Baru"){
          api.post('/absen?token='+this.token,{
            NRK:this.editedItem.Nrk,
            Tanggal: this.editedItem.Tanggal,
            kategoriAbsen: this.editedItem.KategoriAbsen,
            ScanMasuk: (this.editedItem.ScanMasuk != null) ? this.editedItem.ScanMasuk.replace(":", ".") : null,
            ScanPulang: (this.editedItem.ScanPulang != null) ? this.editedItem.ScanPulang.replace(":", ".") : null ,
            Terlambat: (this.editedItem.Terlambat != null) ? this.editedItem.Terlambat.replace(":", ".") : null,
            PulangCepat: (this.editedItem.PulangCepat != null) ? this.editedItem.PulangCepat.replace(":", ".") : null,
            Lembur: (this.editedItem.Lembur != null) ? this.editedItem.Lembur.replace(":", ".") : null,
            Keterangan:this.editedItem.Keterangan,
            },{ headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } })
            .then((res)=>{
              if (res) {
                this.Alert = true
                this.getdata()
              } else {
                //
              }
            })
      }else if(this.formTitleAbsen === "Ubah Data Absen"){
         api.put("/absen/"+this.KodeAbsen+'/?token='+this.token,{
            NRK:this.editedItem.Nrk,
            Tanggal: this.editedItem.Tanggal,
            kategoriAbsen: this.editedItem.KategoriAbsen,
            ScanMasuk: (this.editedItem.ScanMasuk != null) ? this.editedItem.ScanMasuk.replace(":", ".") : null,
            ScanPulang: (this.editedItem.ScanPulang != null) ? this.editedItem.ScanPulang.replace(":", ".") : null ,
            Terlambat: (this.editedItem.Terlambat != null) ? this.editedItem.Terlambat.replace(":", ".") : null,
            PulangCepat: (this.editedItem.PulangCepat != null) ? this.editedItem.PulangCepat.replace(":", ".") : null,
            Lembur: (this.editedItem.Lembur != null) ? this.editedItem.Lembur.replace(":", ".") : null,
            Keterangan:this.editedItem.Keterangan,
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              this.getdata()
              this.AlertBerhasilTerubah = true
						}
						
					})
      }
    },

    filterTgl(){
      this.dialogFilterTgl = true
    },

    Reset() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    rowDblClickNrk(args){
      this.editedItem.Nrk = args.rowData.NRK
      this.editedItem.Nama = args.rowData.NAMA
      this.DialogNrk = false
    },
    rowSelectedNrk(args){
      this.Storage = []
      this.Storage.Nama = args.data.NAMA
      this.Storage.Nrk = args.data.NRK
    },
    SimpanNrk(){
      this.editedItem.Nrk = this.Storage.Nrk
      this.editedItem.Nama = this.Storage.Nama
      this.DialogNrk = false
      this.Storage = []
    },
    rowDblClickKategoriAbsen(args){
      this.editedItem.KategoriAbsen = args.rowData.kategori
      this.DialogKategoriAbsen = false
    },
    rowSelectedKategoriAbsen(args){
      this.Storage = []
      this.Storage.KategoriAbsen = args.data.kategori
    },
     SimpanKategoriAbsen(){
      this.editedItem.KategoriAbsen = this.Storage.KategoriAbsen
      this.DialogKategoriAbsen = false
      this.Storage = []
    },
    logout(){
        api.post('/logout', this.token)
        .then(res=>{
          // console.log(res)
          if (res) {
            //do nothing
          }
        })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        location.reload(false)
      },

    AlertItem(){
      window.setInterval(() => {
        this.Alert = false;
        this.AlertDataTerhapus = false;
        this.AlertBerhasilTerubah = false;
      }, 3000)
    },
  },
}
</script>

<style scoped>
.custom-file-input {
  border: 1px solid #ccc;
  display: inline-block solid #ccc;
  padding: 6px 6px 6px;
  cursor: pointer;
}

.e-grid .e-rowcell.customcss{
  background-color: #d6d6d6;
}
.e-grid .e-headercell.customcss{
  background-color: #ecedee;
}
</style>

{% endraw %}